@include media-breakpoint-up(xl) {
	.container {
		width: 90%;
	}
}

#cookieAlert {
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0;
  position: fixed;
  z-index: $zindex-sticky;
  width: 100%;
}


#searchContainer{
  background-color: $gray-300;

  .typeahead{
    //top: 35px;
    width: 100%;
  }
}

// START: Return to top button
#return-to-top {
  background: $black;
  border: none;
  bottom: 50px;
  cursor: pointer;
  display: none;
  height: 35px;
  opacity: 0.8;
  position: fixed;
  right: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 35px;

  i {
    color: $white;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
  }

  &:hover {
    opacity: 1;
    i {
      top: -3px;
    }
  }

  @include btn-a-style;
}
// END: Return to top button

#site_footer {
  //background-color: $gray-800;
  //color: $gray-500; Marbel footer

  a {
    color: $primary;
		&.active{
			text-decoration:underline;
		}
    &:hover, &:visited, &:active {
      text-decoration: none;
    }
  }
}

.navbar-brand {
	width: 135px;
	/*@include media-breakpoint-down(md) {
		left: 50%;
		margin-left: -52px;
		position: absolute;
		top:0.5rem;
	}
	@include media-breakpoint-down(sm) {
		width:105px;
	}
*/
}