footer{
	.navbar-toggler{
		&:focus{
		outline: none;       
		}
	}
	.custom-control-label{
		font-weight: 300;
		font-size: 11px;
		}
	.fab{
		font-size:20px;
	}
}

// remember to paste this in custom //




