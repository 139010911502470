.elastic-item-container {
  height: 106px;
  display: flex;
  justify-content: space-between;
  padding: 11px 0;
  text-decoration: none!important;
  border: 1px solid #181818;
  width: inherit;
  @include media-breakpoint-only(lg) {
    height: auto;
  }
  @include media-breakpoint-down(md) {
    padding: 11px 3px!important;
  }
  &:hover,&.selected {
    background-color: #202020;
    @include media-breakpoint-down(md) {
      background-color: $gray-400;
    }
  }
}

.result-product-container {
  display: flex;
  background-color: #272727;
  flex-direction: column-reverse;
  @include media-breakpoint-up(lg) {
    flex-direction: unset;
  }
  @include media-breakpoint-down(md) {
    background-color: $white;
  }
  &:hover,&.selected {
    text-decoration: none;
  }
}

.elastic-item-link {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column-reverse;
  @include media-breakpoint-up(lg) {
    width: 80%;
    flex-direction: unset;
  }
  &:hover,&.selected {
    text-decoration: none;
  }
}

.elastic-item-text-box {
  display: flex;
  flex-direction: column;
  height: 80px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 75%;
  max-width: 75%;
  @include media-breakpoint-only(lg) {
    height: auto;
  }
  @media (min-width: 420px) {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.elastic-item-title-and-price {
  display: flex;
  justify-content: space-between;
}

.elastic-item-title {
  span {
    font-size: 1.1rem;
    color: white;
      @include media-breakpoint-down(md) {
        color: $black;
    }
  }
}

ul[data-zg-role='typeahead-ul-elastic'] {
  padding: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  position: relative;
  height: auto;
  width: 710px;
  z-index: 19;
  box-sizing: content-box;
  background-color: transparent;
  @include media-breakpoint-down(md){
    width: 100%;
    position: absolute;
    top: 40px;
  }
  .loading {
    position: absolute;
    min-height: 100px;
  }
}

.elastic-item-price {
  background-color:  $red;
  color: $white;
  padding: 2px 4px;
  height: 28px;
}

.elastic-item-desc {
  font-size: 0.8rem;
  //color:  $gray-600;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 83.333333%;
  @include media-breakpoint-down(md) {
    background-color: $black;
  }
}

.elastic-img-container {
  overflow: hidden;
  img {
    height: auto;
    width: 61px;
    font-size: 0.8rem;
    word-break: break-all;
  }
}

ul[data-elastic='suggestions'] {
  border-left: 1px solid #202020;
  width: 100%;
  padding: 0;
  @include media-breakpoint-up(lg) {
    width: 36%;
    position: absolute;
    right: 0;
    top: 0;
  }
  @include media-breakpoint-down(md){
    border-right: 1px solid #202020;
  }
  p {
    height: auto;
    font-weight: 600;
    padding: 11px 34px;
    margin-bottom: 0;
  }
}

ul li[data-elastic='suggestion']:nth-child(n+3) {
  display:none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

ul div.result-product-container:nth-child(n+4) {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

li[data-elastic='suggestion'] {
  font-weight: bold;
  color: white;
  list-style: none;
  padding: 11px 34px;
  position: relative;
  z-index: 10;
  cursor: pointer;
  em {
    font-weight: normal;
    font-style: normal;
  }
  @include media-breakpoint-down(md) {
    border-top: 1px solid #202020;
    padding: 11px 18px;
    width: 100%;
    color: $black;
  }
  &:hover,&.selected {
    background-color: #202020;
    @include media-breakpoint-down(md) {
      background-color: $gray-400;
    }
  }
}

span[data-elastic='name'], div[data-elastic='desc'] {
  em {
    font-weight: bold;
    font-style: normal;
  }
}

.search-main-container {
  display: flex;
  width: 710px;
  @include media-breakpoint-down(md) {
   width: 100%;
   padding-top: 15px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 0;
  }
}

input[data-zg-role='search-elastic-input'] {
  height: 40px;
  padding-right: 60px;
  color: $white;
  caret-color: $white;
  @include media-breakpoint-down(md){
    padding-right: 130px;
    border-radius: 4px;
    color: $black;
    caret-color: $black;
  }
  &:focus {
    border-color: $gray-400;
    box-shadow: none;
    color: $white;
    @include media-breakpoint-down(md){
      color: $black;
    }
  }
}

#searchElasticContainer {
  width: 100%;
}

.search-btn {
  position: absolute;
  right: 0;
  z-index: 19 !important;
  button[data-zg-role='btn-elastic-search'] {
    z-index: 19 !important;
    span {
      display: block;
    }
  }
}

.erase-btn {
  position: absolute!important;
  right: 40px;
  color: $gray-500;
  background: transparent;
  cursor: pointer;
  padding: 0.45rem 0.65rem;
  z-index: 19 !important;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.opacity-search {
  width: 100vw;
  height: calc(100vh + 100px);
  opacity: 0.4;
  background: $gray-600;
  position: absolute;
  z-index: 9;
  display: block;
  top: -100px;
  left: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

#product-finder-elastic, #product-finder-elastic-mobile {
  .input-group {
    z-index: 19;
    max-width: 285px;
    > .form-control {
      border-bottom: 1px solid $white;
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid $black;
      }
    }
    @include media-breakpoint-down(md) {
      max-width: none;
      background-color: $white;
      width: 50%;
      margin: auto;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}

#product-finder-elastic, #product-finder-elastic-mobile {
  width: 100%;
}

body {
  &.elastic-engine {
    #site_main {
      @include media-breakpoint-down(md) {
      padding-top: 180px;
      }
    }
  }
}