#frmPhoneSend{
  fieldset{
    .form-group{
      #ticket_note{
        border: 1px solid;
      }
      #ticket_attachment{
        @include media-breakpoint-down(lg){
          width: 90%;
        }
        @include media-breakpoint-up(xl){
          width: 100%;
        }
      }
    }
  }
}

.btn-primary{
    border: #dd0934 !important;
}
