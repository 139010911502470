.title-container {
	.title-cart {
		font-size: 2.5rem;
		text-transform: capitalize;
		font-family: Bodoni;
		font-weight: 400;
		@include media-breakpoint-up(md) {
			font-size: 3rem;
			margin-bottom: 2.6rem;
		}
	}
}

.content-custom {
	border-bottom: 1.5px solid rgba(0,0,0,.09);
}

.custom-title {
	font-size: 11px;
}

.total-custom{
	font-size:14px;
	font-family: suiss;
	.btn-promo {
		z-index: 2;
    margin-left: 1px;
    margin-right: 14px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 0;
    height: 44px;
		background-color: #000;
		color: #fff;
	}
	.total-desc {
		font-size:16px;
	}
}


#cart-help {
	.card {
		border:none!important;
		.card-header{
			background-color: #fff;
		}
	}
	.collapse-toggle, .collapse-toggle-upwards {
		&.collapsed:after {
			content: fa-content($fa-var-plus);
		}
	}
}

.product-custom {
 	font-size:1rem;
 	font-family: suiss;
}

.small-desc {
 	font-size:0.7rem;
	font-family: suiss;
} 

.divider {
	margin-bottom: -1rem;
}

.price-custom {
	font-size:1rem;
	font-family: suiss;
}

.custom-size {
	border: 1px solid #000;
	padding: 3px;
}
.qty-custom{
	.form-custom, .btn-outline-secondary{
		height:25px;
	}

	.form-custom{
		border: none;
	}
}

.card-address {
	border:none!important;
}

.img-custom{
	width:150px;
}

.opt-custom {
	width: 50%;
	padding-left: 30px;
}

.wish-custom{
	width:100%;
}

.product-sheet, .remove {
	font-size:12px;
}

.wish-remove {
 width:100%;
}

.wish-title {
	font-size:11px;
}

.custom-checkbox{
	margin: 0;
	position: relative;
	display: block;
}


.radio{
	position: absolute;
	margin-left: -25px;
	margin-top: 1px;
}

.payment-custom {
 .navlink &.active{
	background-color: transparent!important;
 }
}

.products-list-header {
	height: calc(100vh - 300px);
  	overflow-y: auto;
	  overflow-x: hidden;
}

.total-custom {
	font-size: 1.5rem;
}

.custom-inst {
	font-size:0.688rem;
}

.high-custom {
	.register-tab, .login-tab, .guest-tab {
		h3 {
			color: #dd0934;
		}
	}	
}

.suiss-12 {
	font-size: 0.7rem;
}