#breadcrumb-container{
	position:relative;
	z-index:9;
}

b, strong, .bold{
	font-weight:$font-weight-bold;
}
 

/* Navigation nav-pills for desktop and collapse for mobile */
@include media-breakpoint-up(lg){
	.nav-pills-collapse{
		font-size:14px;
		.nav-link{
			font-size: 14px;
			line-height: 14px;
			text-transform: uppercase;
			padding:24px 44px 24px 0;
			border-right: 1px solid $primary;
			color:$primary;
			font-weight: 400;
			position: relative;
			text-align: right;
			&::after{
				position: absolute;
				content: "";
				height: 10px;
				background-color:transparent;
				width: 10px;
				right: -5px;
				border-radius: 50%;
				top: 24px;
			}
			&:first-child{ padding-top:0;
				&::after{
					top:0;
				}
			}

			&:last-child{ padding-bottom:0;
				&::after{
					top:auto;
					bottom: 0;
				}
			}
			&.active{
				background-color:transparent;
				color:$primary;
				&::after{
					background-color: $red;
				}
			}
		}
	}
}
@include media-breakpoint-down(md){
	.nav-pills-collapse{
		.nav-link{
			font-size: 12px;
			text-transform: uppercase;
			text-align: center;
			padding: 20px 0;
			border-bottom: 1px solid;
			position:relative;
			&:after{
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				line-height: 1;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f054";
				position: absolute;
				top: 1.4rem;
				right: 1rem;
				transition-duration: 0.8s;
				transition-property: transform;
			}
			&.active{
				&:after{
					transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
				}
			}
		}
	}
}

$hover-background: darken($red, 7.5%);
.btn-primary{
	font-family: suiss;
	background-color: $red;
	@extend .text-white;
	@include hover {
		color: color-yiq($hover-background);
		@include gradient-bg($hover-background);
  }
	border: none;
}


/* ============================================ *
 * Custom scrollbars
 * ============================================ */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  background: $primary; }

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); }

/* ============================================ */



#product-finder{
	input{
		border-bottom: 1px solid white;
		color: white;
	}

	.typeahead{
		li>a{
				color: white;		
		}
	}
}


body{
	#sidenavSearch{
		width: 480px;
		top:0;
		height: 100vh;
		padding: 0;
		@include media-breakpoint-up(md){
		top: 143px;
		width: 800px;
		min-width: 480px;
		padding: 24px 28px;
		right: 100%;
		height: calc(100vh - 101px);
		}
	}
}

#site_footer{
	font-family: suiss;
	.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
		color: white;
		border:none;
		border-bottom: 1px solid white;
	}

	button{
		font-size: 1.065rem;
	}

	.lead{
		font-size: 1rem;
		line-height: 31px;
		@include media-breakpoint-down(md){
		font-size: 1rem;
		line-height: 21px;
		}
	}
	
	.nav-link{
		font-size: .8rem;
		/*@include media-breakpoint-down(md){
		font-size: 1rem;	
		}*/
	}
	.form-control{ 
	border-radius: 0;
	}
	.form-control:focus{
		box-shadow: 0px 10px 7px -10px rgba(255,255,255,0.75);
-webkit-box-shadow: 0px 10px 7px -10px rgba(255,255,255,0.75);
-moz-box-shadow: 0px 10px 7px -10px rgba(255,255,255,0.75);
	}
}


.input-line{
	color: $white;
	border: none;
	background-color: transparent;
	border-bottom: 1px solid #FFFFFF;
}

.bg-black{
	background-color: $black;
}

.footer_subscribe-text{
	font-family: 'Bodoni';
	font-size: 2.25rem;
	line-height: 50px;
	@include media-breakpoint-down(md){
		//font-size: 28px;
		line-height: 34px;
	}
}

.prefooter{
	&__title{
		font-family: 'suiss';
		font-size: 18px;
		line-height: 23px;
	}
	&__subtitle{
		font-family: 'suiss';
		font-size: 14px;
		line-height: 18px;
		color: $info;
	}
}

.bodoni{
	font-family: Bodoni;
	&__48{
		font-size: 3rem;
		line-height:58px;
	}

	&__76{
		font-size: 4.75rem;
		line-height:90.15px;
	}
}

.suiss{
	font-family: suiss;
	&__spaced{
		letter-spacing: 0.45rem;
	}

	&__24{
		font-size: 1.5rem;
		line-height:38.45px;
	}
}

.tile-link{
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
}

.tile-link-menu{
	position: absolute;
	bottom: -8%;
	padding-right: 7px;
}

.quickbuyCloseButton{
	right: 1%;
	top:1%;
}
#quickbuy{
	.modal-content{
		background: #fff;
	}
}
.jumbotron {
	.jumbotron-text-error{
		top: 40%;
		left: 50%;
		transform: translate(-50%);
	}
}

.img-payment {
	max-width: 10%;
}

body.content_help{
	#help-area{
		padding-bottom:40px;
		h5{
			font-family:'Bodoni';
			@include media-breakpoint-down(md) {
				font-size: 2rem;
			}
		}
		#filter-wrapper .filter .content-filter{
			font-family:suiss;
		}
		#v-pills-tab{
			padding-top:30px;
		}
	}
}



.wish-col:hover, .wish-col:active{
	text-decoration: underline;
	cursor: pointer;
}
.block_lang{
	display: inline-block;
	//position:absolute;
	//right:0;
	//top:0;
	//bottom:0;
	@include media-breakpoint-down(sm){
		top:auto;
		left:0;
		.nav{
			display:block;
			text-align:center;
		}
	}
	padding-right: 12px;
	a{
		padding: .2rem .5rem;
	}
}
.logo-container-footer{
	flex-flow: column;
	.block_lang_footer{
		display: inline-block;
		padding-top: 24px;
		a{
			padding: .2rem .5rem;
		}
	}
}

.custom-back {
	font-size: 0.625rem;
    letter-spacing: 8px;
}

.container-thanks {
	alert-success {
		background-color: #ffffff;
		color: #000000;
		border: none;
		text-align: center;
		font-size: 1.25rem;
	}
}
[data-zg-role="payment-tab"].active{
	label.custom-control-label{
		&::after{
			background-image: $custom-radio-indicator-icon-checked;
		}
		&::before {
			border-color: #000;
    	background-color: #000;
		}
	}
}
.page_home h1{
	font-size:64px;
	font-weight:400;
	height:auto;
	line-height:77px;
	@include media-breakpoint-down(sm) {
		font-size: 3rem;
		line-height: 58px;
	}
}

#CybotCookiebotDialog{
	#CybotCookiebotDialogBodyContentTitle{
		font-family: Bodoni !important;
		font-size: 14pt;
	}
	#CybotCookiebotDialogBody{
		div{
			font-family: Bodoni !important;
			font-size: 14px; 
		}
	}
	#CybotCookiebotDialogBodyButtons a{
		font-family: suiss!important; 
		font-size: 14px; 
		
			#CybotCookiebotDialogBodyButtonAccept{
		    background-color: #dd0934 !important;
			border: 1px solid #dd0934 !important;
		}
	}
	#CybotCookiebotDialogBodyButtonDetails a{
		    font-family: Bodoni!important;
    		font-size: 14px;
			margin-top: 0px !important;
	}
	#CybotCookiebotDialogBodyButtonAccept{
		    background-color: #dd0934 !important;
			border: 1px solid #dd0934 !important;
	}
}    

.text-red {
	color: #dd0934!important;
}

#newsletter_form_footer .input-group, #form_newsletter_modal .input-group{
	width:100%;
}

#newsletterModal{
	.nl-img-bg{
		position: absolute;
		left: -10%;
		right: -10%;
		img{
			width:100%; 
			line-height: 1.83;
		}
	}

	.custom-ctr-label {
		color: $primary!important;
		font-weight: 300;
        font-size: 11px;
		text-align: left;
		@include media-breakpoint-down(sm) {
			text-align: center;
		}

	}
	

	.content-form-nl{	
		position: absolute;
		text-align:center;
		margin: 8% 20%;
		width:60%;
		background:#fff;
		padding:33px 60px;
		
		.h1{
			font-size: 24px;
			font-weight: 300;
			margin-bottom:18px;
		}
		.custom-control-label {
			font-weight: 300;
    		font-size: 11px;
		}

		.close{
			position: absolute;
			right: 15px;
			top: 15px;
			line-height: 20px;
		}
		input#ns_email{
			text-align:center;
			font-size: 1rem;
		}
		.checkbox label{
			padding:0 15px;
			.privacy-check{
				width: 100%;
				left:0;
				position:relative;
				&:before{
					left: 50%;
					margin-left: -10px;
				}
				&:after{
					left: 50%;
					margin-left: -6px;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.modal-dialog{
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
			
			.nl-img-bg{
				position: relative;
				left: 0;
				right: 0;
			}
			.content-form-nl {
				position: relative;
				text-align: center;
				margin: 0;
				width: 100%;
				background: #fff;
				padding: 20px;
				.checkbox label {
					padding: 0 10px;
				}
			}
		}
	}
}

#privacy_modal {
	z-index: 9999;
	.modal-content {
		background-color: #ffffff;
	}
}

.invert-100{
	filter: invert(100%);
}

.item-hp {
	@include media-breakpoint-down(sm) {
		min-height: 500px;
	}
}

.btn-cover{
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	z-index: 99;
}

.btn-link-hp{
	z-index: 3;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
	bottom: 15%;
}





.center{
  text-align: center;
}

.container_center-flex{
  margin:auto;
  display:flex;
  flex-direction: column ;
}

.progressbar_container{
  align-self:center;
  justify-self:center;
  display:flex;
  align-items: center;
  height: 50px;
	width: 95%;
}

.progressbar_main{
  border-radius:0px;
  background-color: #aaa;
  height: 20px;
	width: 100%; 
  margin: auto;
}

.progressbar_main-done{
  border-radius:0px;
  height:100%;
  width:30px;
  background: #dd0934 //#ff7e00;
}

.text-bottom{
  font-size: 20px;
  line-height: 1.6;
}

.light-orange-box{
	background-color: hsla(30,100%,50%,0.2);
	
	padding: 20px;
	border-radius: 0px;
}

.discounted{
	//color:#5d5454;
	color:#f8a008;
}

.opt-sty{
	top:50px;
}

.cart-st{
	right: 70px;
}