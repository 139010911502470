.grid-678705 {
	display: grid;
	width: 100%;
	grid-auto-flow: column;
	grid-template-columns: auto;
	grid-template-rows: auto;
	gap: 15px 15px;
	justify-content: center;
	align-items: center;


	.item-1 {
		grid-column: 1 / 3;
		grid-row: 1 / 3;
	}

	.item-2 {
		grid-column: 3 / 4;
		grid-row: 1 / 2;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-3 {
		grid-column: 4 / 5;
		grid-row: 1 / 2;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-4 {
		grid-column: 5 / 6;
		grid-row: 1 / 2;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-5 {
		grid-column: 3 / 5;
		grid-row: 2 / 3;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-6 {
		grid-column: 5 / 6;
		grid-row: 2 / 3;
		justify-self: stretch;
		align-self: stretch;
	}

	@media (max-width:768px) and (min-width:0px) {
		.item-1 {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		.item-2 {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		.item-3  {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
		.item-4  {
			grid-column: 1 / 2;
			grid-row: 4 / 5;
		}
		.item-5  {
			grid-column: 1 / 2;
			grid-row: 5 / 6;
		}
		.item-6  {
			grid-column: 1 / 2;
			grid-row: 6 / 7;
		}
	}
}

.point-position- {
	&0 {
		display: none;
	}
	&1 {
		position: absolute;
		top: 8%;
		left: 8%;
		width: 5%;
	}
	&2 {
		position: absolute;
		top: 8%;
		left: 50%;
		width: 5%;
	}
	&3 {
		position: absolute;
		top: 8%;
		left: 92%;
		width: 5%;
	}
	&4 {
		position: absolute;
		top: 50%;
		left: 8%;
		width: 5%;
	}
	&5 {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 5%;
	}
	&6 {
		position: absolute;
		top: 50%;
		left: 92%;
		width: 5%;
	}
	&7 {
		position: absolute;
		top: 92%;
		left: 8%;
		width: 5%;
	}
	&8 {
		position: absolute;
		top: 92%;
		left: 50%;
		width: 5%;
	}
	&9 {
		position: absolute;
		top: 92%;
		left: 92%;
		width: 5%;
	}
}





.grid-679002 {
	display: grid;
	width: 100%;
	grid-auto-flow: column;
	grid-template-columns: auto;
	grid-template-rows: auto;
	gap: 15px 15px;
	justify-content: center;
	align-items: center;


	
	.item-1 {
		position: relative;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	.item-2 {
		position: relative;
		grid-column: 1 / 2;
		grid-row: 2 / 3;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-3 {
		position: relative;
		grid-column: 2 / 3;
		grid-row: 1 / 3;
		justify-self: stretch;
		align-self: stretch;
	}
	@media (max-width:768px) and (min-width:0px) {
		.item-1 {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		.item-2 {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		.item-3  {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
	}


}




.grid-679118{
	display: grid;
	width: 100%;
	grid-auto-flow: column;
	grid-template-columns: auto;
	grid-template-rows: auto;
	gap: 15px 15px;
	justify-content: center;
	align-items: center;

	.item-1 {
		grid-column: 1 / 3;
		grid-row: 1 / 3;
	}

	.item-2 {
		grid-column: 3 / 4;
		grid-row: 1 / 2;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-3 {
		grid-column: 4 / 5;
		grid-row: 1 / 2;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-4 {
		grid-column: 5 / 6;
		grid-row: 1 / 2;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-5 {
		grid-column: 3 / 5;
		grid-row: 2 / 4;
		justify-self: stretch;
		align-self: stretch;
	}

	.item-6 {
		grid-column: 5 / 6;
		grid-row: 2 / 3;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-7{
		grid-column: 1 / 2;
		grid-row: 3 / 4;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-8{
		grid-column: 2 / 3;
		grid-row: 3 / 4;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-9{
		grid-column: 5 / 6;
		grid-row: 3 / 4;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-10{
		grid-column: 1 / 2;
		grid-row: 4 / 5;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-11{
		grid-column: 2 / 3;
		grid-row: 4 / 5;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-12{
		grid-column: 3 / 4;
		grid-row: 4 / 5;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-13{
		grid-column: 4 / 5;
		grid-row: 4 / 5;
		justify-self: stretch;
		align-self: stretch;
	}
	.item-14{
		grid-column: 5 / 6;
		grid-row: 4 / 5;
		justify-self: stretch;
		align-self: stretch;
	}
	@media (max-width:768px) and (min-width:0px) {
		.item-1 {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		.item-2 {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		.item-3  {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
		.item-4  {
			grid-column: 1 / 2;
			grid-row: 4 / 5;
		}
		.item-5  {
			grid-column: 1 / 2;
			grid-row: 5 / 6;
		}
		.item-6  {
			grid-column: 1 / 2;
			grid-row: 6 / 7;
		}
				.item-7  {
			grid-column: 1 / 2;
			grid-row: 7 / 8;
		}
				.item-8  {
			grid-column: 1 / 2;
			grid-row: 8 / 9;
		}
				.item-9  {
			grid-column: 1 / 2;
			grid-row: 9 / 10;
		}
				.item-10  {
			grid-column: 1 / 2;
			grid-row: 10 / 11;
		}
				.item-11  {
			grid-column: 1 / 2;
			grid-row: 11 / 12;
		}
				.item-12  {
			grid-column: 1 / 2;
			grid-row: 12 / 13;
		}
				.item-13  {
			grid-column: 1 / 2;
			grid-row: 13 / 14;
		}
				.item-14  {
			grid-column: 1 / 2;
			grid-row: 14 / 15;
		}

	}

}