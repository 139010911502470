.landingCnt{
			.margin-fix{
				margin-top: -8%;
			}
			.landing-componentImagesAndDescriptions{
					display: grid;
					grid-template-columns: 15% 40% 35% 10%;
					grid-template-rows: 10% 25% 20% 45%;
					//min-height: 110vh;  
					min-width: 0;   
					column-gap: 7px;
					//margin-top: -8%;
				}

					.quickBuyLinks{
					position: absolute;
					left:15px;
					bottom:0;
					width:calc(100% - 30px);
					height: 300px;	
					background-color: #f1f1f1;
						h5, h4, h3 {
						font-size: 11px;
						}
						
					}
					.quickBuyLinks_fixedHeight{
						height: 100%;
						display: flex;
						flex-direction: column;
							@include media-breakpoint-up(xl){
								height:80%;
							}
							@media (min-width: 768px) and (max-width: 991.98px) { img{ width: 70% }}
							@media  (min-width: 0px) and (max-width: 321px){
								h5{
								max-height:4%;
								overflow-y: hidden;
								}
							}
							@include media-breakpoint-down(lg){
								h5{
								max-height:50%;
								overflow-y: hidden;
								}
							}		
					}
					
					.quickBuyLinks_noFix{
						width: 100%;
						left:0;
					}
				.image_1{
					grid-column-start: 3;
					grid-column-end: 4;
					grid-row-start: 1;
					grid-row-end: 4;
					justify-self: start;

				}

				.image_2{
					grid-column-start: 2;
					grid-column-end: 3;
					grid-row-start: 3;
					grid-row-end: 5;
					justify-self: end;
					padding: 5px 0 0 0;
				}

				.description_1{
					grid-row-start: 2;
					grid-row-end: 3;
					grid-column-start: 1;
					grid-column-end: 3;
					line-height: 2.11;
					font-size: 18px;
					padding: 60px 190px 60px 0;
					display: flex;
					align-items:center;
						@include media-breakpoint-down(lg){
							padding-top: 0px;
						}
					}

				.description_2{
					grid-row-start: 4;
					grid-row-end: 5;
					grid-column-start: 3;
					grid-column-end: 5;
				  font-size: 14px;
  				line-height: 1.71;
					padding: 140px 0 0 94px;
							@include media-breakpoint-down(lg){
							padding-top: 30px;
						}
				}

				.description-mobile{
					font-size: 14px;
					line-height: 1.57;
				}
				h1{
				  font-size: 76px;
					font-weight: 600;
					line-height: 1;
						@include media-breakpoint-down(lg){
							font-size: 60px;
						}
						@include media-breakpoint-down(md){
						font-size: 40px;
						}
						@include media-breakpoint-down(sm){
						font-size: 27px;
						}
						
				}

				h2{
					font-size: 46px;
  				font-weight: 600;		
					line-height: 1.04;
						@include media-breakpoint-down(lg){
						font-size: 36px;
						}
						@include media-breakpoint-down(md){
						font-size: 24px;
						}
						@include media-breakpoint-down(sm){
						font-size: 16px;
						}
				}
				.h3{
					font-size: 18px;
					line-height: 2.11;
					@include media-breakpoint-down(lg){
						font-size: 16px;
						}
						@include media-breakpoint-down(md){
						font-size: 14px;
						}
						@include media-breakpoint-down(sm){
						font-size: 14px;
						}
				}
				.h4{
					font-size: 14px;
					line-height: 1.71;
					@include media-breakpoint-down(lg){
						font-size: 14px;
						}
						@include media-breakpoint-down(md){
						font-size: 14px;
						}
						@include media-breakpoint-down(sm){
						font-size: 12px;
						}
				}
				.h5{
					@include media-breakpoint-down(md){
						line-height: 1.71;
						}
				
				}


			.info_button{
				position: absolute;
				bottom: 4%;
				right: 4%;
				background-color: white;
				border-radius: 50%;
				@include media-breakpoint-down(lg){
							bottom: 8%;
							right: 8%;
						}
			
			}


			.info_button-lookbook{
				position: absolute;
				bottom: 4%;
				right: 8%;
				background-color: white;
				border-radius: 50%;
				@include media-breakpoint-down(lg){
							bottom: 10%;
							right: 10%;
						}
				
			}
			.info_button-lookbook-mobile{
				position: absolute;
				bottom: 4%;
				right: 4%;
				background-color: white;
				border-radius: 50%;
				@include media-breakpoint-down(lg){
							bottom: 8%;
							right: 8%;
						}
			
			}

			.info-center{
				position: absolute;
				bottom: 4%;
				right: 50%;
				transform: translate(50%);
				background-color: white;
				border-radius: 50%;
				@include media-breakpoint-down(lg){
							bottom: 8%;
							right: 50%;
						}
			}
				.shopThisPhoto-text{
				position: absolute;
				bottom: 8%;
				right: 50%;
				transform: translate(50%);
				-webkit-text-fill-color: white; 
  			//-webkit-text-stroke-width: 1px;
  			//-webkit-text-stroke-color: black;
				@include media-breakpoint-down(lg){
							bottom: 12%;
							right: 50%;
						}
			}
		
		

}




