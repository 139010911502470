//START: OVERRIDE BOOTSTRAP VARIABLES
$greenk:                 #08FACC;
$nav-link-padding-y:     .3rem;
//END: OVERRIDE BOOTSTRAP VARIABLES

$enable-rounded:         false;
$height-navbar-brand:    87px;
$height-navbar-brand-lg: 0px;
$color-facebook:         #4267B2;
$color-google-plus:      #DB4437;
$color-twitter:          #00ACEE;
$color-pinterest:        #C8232C;
$z-index-100:            100;
$zindex-dropdown:        1001;



