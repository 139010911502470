#account-area {
	&.nav-pills-collapse{
		.nav-link{
			font-size: 17px;
			line-height: 20px;;
		}
	}
	.tab-content, .card-content {
	
		font-size: 14px;
		
		.card-account {
			//background-color:rgba(0,0,0,.03);
			//height: 250px;
		}

		.address-list{
			>div{display:none;}
			>div.add-shipping{
				display:block;
				flex: 0 0 100%!important;
    			max-width: 100%!important;
				padding: 0;
				margin-top:0!important;
			}
		}
		.card{
			border:none;
		}
		.card-custom {
			//background-color:rgba(0,0,0,.03);
			//height: 350px;
			dt{font-size:12px;}
			dd{
				font-size:20px;
				line-height: 30px;
    			margin-bottom: 15px;
			}
		}
		.card-address{
			background-color:#fff!important;
		}
		.panel-heading{
			padding: .75rem 1.25rem;
		}
	}

	#tabs-account{
		&.nav-tabs{
			//border-color:transparent;
		}

		.nav-item {
			width: 75%;
			padding:0;
		}

		.border-color {
			//border-bottom:2px solid #000;
		}

		.nav-item a {
			text-decoration: none;
		}

		.nav-item a.active {
			//border-bottom:2px solid #000;
		}

		.nav-ref {
			text-transform:none;
			padding:22px 0;
			color:#e1dbd0;
			&.active {
				color: #000;
			}
		}


	 .h5{
			margin:0;
		}

	}

}

#addresses .card-footer {
	border: none!important;
}