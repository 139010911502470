/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

$img-path: "//img02.aws.kooomo-cloud.com/themes/1473/Rede_Version_01/images" !default;
@import "base/kooomo/km-variables";

@import "base/bootstrap/functions";
@import "base/bootstrap/variables";
@import "base/kooomo/km-overwritten-variables";
@import "base/bootstrap/mixins";
@import "base/bootstrap/root";
@import "base/bootstrap/reboot";
@import "base/bootstrap/type";
@import "base/bootstrap/images";
@import "base/bootstrap/code";
@import "base/bootstrap/grid";
@import "base/bootstrap/tables";
@import "base/bootstrap/forms";
@import "base/bootstrap/buttons";
@import "base/bootstrap/transitions";
@import "base/bootstrap/dropdown";
@import "base/bootstrap/button-group";
@import "base/bootstrap/input-group";
@import "base/bootstrap/custom-forms";
@import "base/bootstrap/nav";
@import "base/bootstrap/navbar";
@import "base/bootstrap/card";
@import "base/bootstrap/breadcrumb";
@import "base/bootstrap/pagination";
@import "base/bootstrap/badge";
@import "base/bootstrap/jumbotron";
@import "base/bootstrap/alert";
@import "base/bootstrap/progress";
@import "base/bootstrap/media";
@import "base/bootstrap/list-group";
@import "base/bootstrap/close";
@import "base/bootstrap/toasts";
@import "base/bootstrap/modal";
@import "base/bootstrap/tooltip";
@import "base/bootstrap/popover";
@import "base/bootstrap/carousel";
@import "base/bootstrap/spinners";
@import "base/bootstrap/utilities";
@import "base/bootstrap/print";


@import "base/kooomo/km-mixins";
@import "base/kooomo/km-product";
@import "base/kooomo/km-global";
@import "base/kooomo/km-gritter";
@import "base/kooomo/km-comments-rating";
@import "base/fontawesome/brands";
@import "base/fontawesome/regular";
@import "base/fontawesome/solid";
@import "base/fontawesome/fontawesome";
@import "base/kooomo/km-utilities";
@import "base/kooomo/km-compare-products";
@import "base/kooomo/km-filter";
@import "base/kooomo/km-checkout";
@import "base/kooomo/nouislider";
@import "base/kooomo/zg-header";
@import "base/kooomo/zg-custom";
@import "base/kooomo/zg-grid";
@import "base/kooomo/zg-footer";
@import "base/kooomo/zg-userAccount";
@import "base/kooomo/zg-category";
@import "base/kooomo/zg-cart";
@import "base/kooomo/zg-confirm";
@import "base/kooomo/zg-landing";
@import "base/kooomo/zg-product";
@import "base/kooomo/tiny-slider";
@import "base/kooomo/zg-sizeguide";
@import "base/kooomo/zg-fonts";
@import "base/kooomo/zg-carousel";
@import "base/kooomo/km-cms-highlighter";
@import "base/kooomo/km-contact";
@import "base/kooomo/km-elasticsearch";