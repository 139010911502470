#carousel-674617{
	font-family: Bodoni; 
	font-size: 64px;
	line-height: 28px;
	
	.carousel-caption{
		//width: 35%;
		bottom:50%;
		//text-align: left;
		//left: 5%;
		transform: translateY(50%);
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
	//.carousel-button{
	//	position: absolute;
	//	bottom:34%;
	//	right:45%;
	//	transform: translateY(50%);
	//font-size: 26px;
	//}
	
}

.description-carousel {
	font-size: 1.8rem;
	@include media-breakpoint-down(sm){
		font-size: 1.4rem;
	}
}

.carousel-inner{
	.carousel-item{
		.gradientBkg{
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			background: rgba(0, 0, 0, 0.4);
		}
	}
}

.carousel-control-prev {
	
	@include media-breakpoint-up(md){
		//margin-left: 6.25rem;
		//background-color: #bebebe;
	}
}

.carousel-control-next {
	
	@include media-breakpoint-up(md){
		//margin-right: 6.25rem;
		//background-color: #bebebe;
	}
}