.page_category{
	h1{
		font-weight:400;
		font-family: Bodoni;
		text-align:center;
		margin-bottom:30px;
		margin-top:10px;
	}
	.container-btn-quickbuy{
	padding:3px 22px;
		@include media-breakpoint-down(sm) {		
			//display:none!important;
		}
	}
	#filter-wrapper{
		text-align:right;
		.btn-filters{
			cursor:pointer;
			text-transform:uppercase;
		}
	}
	.dropdown-sortBy{
		color:#000;
		cursor:pointer;
		.btn-sortBy:after{
			display:none;			
		}
		@include media-breakpoint-down(sm) {		
			.btn-sortBy{
				#show{
					display:none;
				}
			}
		}
		.dropdown-menu{
			border:0;
		}
	}
	
	.btn-secondary{
		border: 1px solid #000000;
	}
	.products_list{
		font-family: suiss;
		font-size: 1rem;
		overflow:hidden;
		.product-preview{
			margin-bottom:12px;
			@include media-breakpoint-up(md) {
				margin-bottom:150px;
			}
		}
		.price{
			font-size: 1rem;
			@include media-breakpoint-down(sm) {
				font-size: 0.95rem;
				text-align:left;
				margin:20px 0;
			}
		}
		/*.name{
			font-size: 1rem;
			line-height: 2rem;
   	 	margin: 0;
		}*/
		article{
			.product-options{
				.name{
					line-height: 18px;
					height: 60px;
					font-size: 1rem;
					margin: 0;
				}
			}
			@include media-breakpoint-up(md) {
				.product-options{
					position:absolute;
					bottom:0;
					left:30px;
					right:0;
					height:2rem;
					display:flex;
					.name{
						height: 60px;
					}
				}
				.product-content{
					margin-bottom:3rem;
				}
				
			}
		}
		.banner_in{
			font-family:Bodoni;
			font-weight: 400;
			text-decoration:none;
			cursor:pointer;
			height: 100%;
			align-items: center;
			align-content: center;
			display: flex;
			flex-flow: row wrap;
			background-position: center;
			background-size:100%;
			background-size:cover;
			color:#fff;
			position:relative;
			@include media-breakpoint-down(sm) {
				padding-bottom:66%;
			}
			/*@include media-breakpoint-up(md) {
				min-height:350px;
			}*/
			.container-text{
				cursor:pointer;
				@include media-breakpoint-down(sm) {
					position:absolute;
					margin:0;
					padding:0;
					left:0;
					top:0;
					bottom:20px;
					right:0;
					display:flex;
					flex-flow: row wrap;
					align-items: center;
					justify-content: space-around;
    			height: 100%;
				}
			}
			.title{
				font-size: 3rem;
				line-height:1;
				display:block;
			}
			.subtitle,.btnText{
				text-transform:uppercase;
				font-family: suiss;
				letter-spacing: 0.45em;
				display:block;
			}
			.btnText{
				position:absolute;
				bottom:4rem;
				left:0;
				right:0;
				@include media-breakpoint-down(md) {
					bottom:2rem;
				}
				@include media-breakpoint-down(sm) {
					bottom:1rem;
				}
			}
			> *{
				text-align:center;
				width: 100%;
				cursor:pointer;
				color:#fff;
			}
		}
	}
}
#quickbuy{
 font-family:suiss;
 .close{
 	z-index:1;
 }
 .modal-dialog.modal-lg{
 		@include media-breakpoint-up(md) {
			max-width:920px;
		}
 }
	[data-option-id="2"]{
		a{
			opacity:.5;
			border: 1.5px solid #000000;
			&:hover,&.active{
				opacity:1;
				background-color: #000;
				color: #fff;
			}
		}
	}
	.image-option{
		&:hover{
			text-decoration:none;
		}
		img{
			border-radius: 14px;
			overflow: hidden;
			padding: 0;
			width: 28px;
			height: 28px;
			display: inline-block;
			border: 1px solid #ccc;
		}
		&.active{
			img{
				border: 2px solid #000000;
			}
		}
	}
	[data-zg-role="add-to-cart"]{
		font-size:1.5rem;
	}
	 #zoomMain{
			display: flex;
			align-items: center;
			height: 100%;
	 }
	 .h3{
		font-family:Bodoni;
		font-size:2.75rem;
		line-height: 1;
	 }
	> .h4{
	 font-size: 2rem;		
		text-decoration:none;
		
	}
 .container-description{
		margin-top:30px;
		border:0;
		.h4{
			text-transform:uppercase;
			font-size: 1rem;
			padding:20px 0 10px;
			display:inline-block;
			border-bottom:solid 1.5px #fff;
			background-color: white;
			@include media-breakpoint-up(lg){
				font-size: 1.125rem;
			}
			&.active{
				text-decoration:none;
				border-bottom:solid 1.5px #000;
			}
			&:hover{
				text-decoration:none;
				border-bottom:solid 1.5px #000;
			}
		}	
 }
	.wish-col{
		margin-top:30px;
		text-transform: uppercase;
		cursor:pointer;
		svg{
			position: relative;
    	top: -2px;
		}
}
	.tab-content{
		margin-top:25px;
		text-align:left;
		padding-bottom:30px;
		.active{
			margin:0;			
		}
	}
}
/*.jumbotron.block-category-info{
	padding:0;
	
}

.btn-sortBy{
	padding: 0.5rem 0.75rem 0.5rem;
	font-size: 11px;
	border-radius: 18px;
	width: 100%;
	font-weight:300;
	&.dropdown-toggle{
		&:after {
			@include fa-icon();
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			line-height: 1.5rem;
			content: fa-content($fa-var-chevron-down);
			position: absolute;
			top: 0;
			right: 0;
			text-decoration: none !important;
			text-align: center;
			border: none;
			margin: .2rem .75rem 0 0;
			vertical-align: initial;
		}
	}
}

.btn-filter{
	font-size: 11px;
	padding:.5rem 0 .5rem;
	&:after{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		line-height: 1;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		content: "\f054";
		position: absolute;
		top: .60rem;
		text-decoration: none!important;
		text-align: center;
		border: none;
		margin: 0 0 0 1rem;
		vertical-align: initial;
		transition-duration: 0.8s;
		transition-property: transform;
	}
}

@include media-breakpoint-down(md) {
	#filter-wrapper{
		position: fixed;
		height: 100vh;
		background-color: #fff;
		top: 0px;
		left: -100%;
		width: 100%;
		z-index: 99;
		padding: 2rem;
		overflow-y: scroll;
    overflow-x: hidden;
	}
}

@include media-breakpoint-up(md) {
	.btn-sortBy{
		padding: 0.5rem 1.25rem 0.5rem;
	}
	.reset-wrapper{
		position: absolute;
    top: -53px;
    right: 0;
		.btn{
			padding: 16px 13px;
			border: none!important;
			font-size: 12px;
			font-weight: 300;
			text-decoration: underline;
		}
	}
	#filter-wrapper{
		position:absolute;
		z-index: 9;
		background-color: #fff;
		width: 100%;
		top: -24px;
		.btn-filter{
			padding:2rem 0 .5rem;
			&:after{
				top: 2rem;
			}
		}
		#filter-container{
			padding: 0 1rem .5rem 0;
			position:relative;
		}
		&.show{
			border-right: 1px solid #dee2e6;
			border-bottom: 1px solid #dee2e6;
			.btn-filter{
				&:after{
					transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
					//content: none;
					background: url("#{$img-path}/base/close-png.png");
					background-size: 20px 20px;
					content: '';
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
*/