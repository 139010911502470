#filter-container{
	position:absolute;
	right:0;
	left:0;
	width:100%;		
	background: #fff;
	&.show,&.collapsing{
		display:flex;
		padding:40px 40px 120px;
		z-index: 11111;
		
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 111;
		background: #fff;
		&.show{
			display:block;
			padding:40px 30px 10%;
		}

	}
	.apply-filter,.reset-wrapper{
		position:absolute;
		bottom:20px;
		left:40px;
		button{
			text-transform:uppercase;
		}
		&.reset-wrapper{
			left:auto;
			right:40px;
		}
	}
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap; 
	}
}

#filter-wrapper .filter {
	flex: 1 1 0px;
	padding-right:30px;
	@include media-breakpoint-down(sm) {
		width: 50%;
    float: left;
		height:45%;
		overflow: auto;
	}
  .name {
    @extend .mb-4;
    @extend .text-truncate;
   	text-align:left;
		font-size: 1.4rem;
		text-transform: uppercase;
		font-weight:400;
		flex:1 1 0px;
		
  }
	.container{
		margin-left:0;
	}
  .content-filter {
    @extend .mb-3;
    @include media-breakpoint-up(sm) {
      max-height: 300px;
      overflow-y: auto;
    }
    a {
      color: $gray-900;
      line-height: 1em;
      padding-left: 1.5em;
      position: relative;
			text-align:left;
			padding-left:0;
      @extend .mb-2;
			overflow:hidden;			
      .name {
        text-transform: uppercase;
      }
			&.active {
				text-decoration:underline;
			}
			&.img-filter{
				.option-image {
					width:24px;
					height:24px;
					border-radius:12px;
					overflow:hidden;
					border:solid 1px #ccc;
				}
				&.active {
					text-decoration:none;
					.option-image {
						border:solid 1px #000;
						text-decoration:none;
					}
				}
			}
    }
  }


}