//fix top body for fixed header
#site_main{
	padding-top:195px;
	@include media-breakpoint-down(sm){
		padding-top:109px;
	}
}
/*SIDENAV CART*/
.sidenav {
	height: calc(100vh - 101px);
	width: 480px;
	position: fixed;
	z-index: 999;
	right: -524px;
	top: 143px;
	transition: all .8s;
	background:$gray-153;
	display:block;
	color:#fff;
	padding:24px 28px;
	border: 0;
	margin: 0;
	font-family:$input-btn-font-family;
	overflow:hidden;
	h5{
		font-size: 42px;
		text-transform: capitalize;
		font-family: Bodoni;
		font-weight: 400;
		margin-bottom: 42px;
	}
	.close {
		opacity: 1;
		position: absolute;
		right: 30px;
		top: 35px;
		cursor: pointer;
	}
	svg {
    fill: transparent;
	}
	&#sidenavCart,&#sidenavWish{
	
		@include media-breakpoint-down(sm){
			display:none!important;
		}
		span,a{
			color:#fff;
		}
		svg path{
			fill:#fff;
		}
		.h5{
			font-size:42px;
			text-transform:capitalize;
			font-family:Bodoni;
			font-weight:400;
			margin-bottom:42px;
		}
		.close{						
			opacity: 1;
			position: absolute;
			right: 30px;
			top: 35px;
			cursor:pointer;
		}
		.subtotal{
			margin: 34px 0 22px;
		}
		.price{
			word-spacing: -8px;
		}
		[data-zg-role="list-items-container"]{
			height:100%;
			.products-list{
				height:44%;
				@include media-breakpoint-up(md){
					height:55%;
				}
				@media (min-width: 1200px) and (max-height: 800px) {
					height:40%;
				}
				overflow-y: auto;
				overflow-x: hidden;
				.products-list-header {
						height: auto;
						
				}
			}
		}
		.list-product{
			.remove{
				display:flex;
				align-items: center;
				a{
					//position:relative;
					//left:-14px;
				}
			}

			.image{
				display:flex;
				background:#fff;	
				padding:4px;
				align-items: center;
				img{
					margin:auto;
				}
			}
			.list-details{
				height: 100%;
				.name{
					line-height: 18px;
					display:block;
				}
				.opt-Taglia,.opt-Size{
					display:none;
				}
				.option{
					margin-bottom:14px;
				}
				.qty{
					margin-bottom:14px;
					text-transform: lowercase;
				}
			}
		}
	}

}

#site_header{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 99;
	.right-menu-mobile{
		width: 33%;
    	justify-content: space-between;
		>div{
			max-width:50%;
		}
		svg,path{
			fill:transparent;
		}
	}
	#navbarNavLeft,#navbarNavRight{
		width:45%;
		.nav{
					align-items: center;
		}
		.nav-link{
			padding:0 1rem;
		}
		.dropdown-menu-full {
			width: auto;
			left:-20px;
			right:-20px;
			border: 0;
			padding: 2rem 20px;
			margin-top: 0;
			min-height: 15.625rem;
			border-top: solid 16px #fff;
			margin:0;
			.dropdown-item{
				text-decoration: none;
			}
			.w-100{
				p{
					white-space: break-spaces;
					font-size:18px;
					margin-top:40px;
				}
				>ul{
				display:flex;
					>li.dropdown-item{
						overflow:hidden;
						>a{
							font-size:1.5rem;
							font-family:Bodoni;
							/*@include media-breakpoint-up(xl) {
								font-size:1.5rem;
							}*/
						}
					}
				}
			}
		}
		>div{
			width:100%;
			>div{
				margin-right:12px;
				overflow: hidden;
				padding-bottom: 30px;
				margin-bottom: -30px;
				@include media-breakpoint-up(xl) {
					margin-right:1rem;
				}
			}
		}
		.main-menu-link{
			color:$black;
			font-family:Bodoni;
			margin:0;
			
		}

		
		.nav-item,.nav-link{
			svg,path{
			//	fill:transparent;
			}
			.cart,.wish{
				padding: 0 1rem;
				display: flex;
				position:relative;
				cursor:pointer;
				&:before{
					content:"";
					display:block;
					position:absolute;
					height:4px;
					width:4px;
					border-radius:5px;
					background-color:$red;
					bottom:3px;
					left: 55%;
					margin-left: -5px;		
					@include media-breakpoint-up(xl) {
						bottom:6px;
					}
				}
				&.wish:before,&[count-items="0"]:before{
					display:none;
				}	
				+.dropdown-menu{
					@include media-breakpoint-up(lg) {
						top: 76px;
					}
					right:-100%;
					left:auto;
					transition: all .8s;
					background:$gray-153;
					display:block;
					color:#fff;
					padding:24px 28px;
					font-family:$input-btn-font-family;

					&.show{
						right:-65px;
					}
					span,a{
						color:#fff;
					}
					svg path{
						fill:#fff;
					}
					.h5{
						font-size:42px;
						text-transform:capitalize;
						font-family:Bodoni;
						font-weight:400;
						margin-bottom:42px;
					}
					.close{						
						opacity: 1;
						position: absolute;
						right: 80px;
						top: 35px;
						cursor:pointer;
					}
					.subtotal{
						margin: 34px 0 22px;
					}
					.price{
						word-spacing: -8px;
					}
					.list-product{
						.remove{
							display:flex;
							align-items: center;
							a{
								//position:relative;
								//left:-14px;
							}
						}
						
						.image{
							display:flex;
							background:#fff;	
							padding:4px;
							align-items: center;
							img{
								margin:auto;
							}
						}
						.list-details{
							.name{
								line-height: 18px;
								display:block;
							}
							.opt-Taglia,.opt-Size{
								display:none;
							}
							.option{
								margin-bottom:14px;
							}
							.qty{
								margin-bottom:14px;
								text-transform: lowercase;
							}
						}
					}
				}
			}
		}
	}
	#navbarNavCenter{
		.nav{
					align-items: center;
		}
		.nav-link{
			padding:0 1rem;
		}
		.dropdown-menu-full {
			width: auto;
			left:-20px;
			right:-20px;
			border: 0;
			padding: 2rem 20px;
			//margin-top: 0;
			min-height: 15.625rem;
			border-top: solid 16px #fff;
			margin:0;
			margin-top: 10px;
			.dropdown-item{
				text-decoration: none;
			}
			.w-100{
				p{
					white-space: break-spaces;
					font-size:18px;
					margin-top:40px;
				}
				>ul{
				display:flex;
					>li.dropdown-item{
						overflow:hidden;
						>a{
							font-size:1.5rem;
							font-family:Bodoni;
							/*@include media-breakpoint-up(xl) {
								font-size:1.5rem;
							}*/
						}
					}
				}
			}
		}
		>div{
			width:100%;
			>div{
				margin-right:12px;
				overflow: hidden;
				padding-bottom: 30px;
				margin-bottom: -30px;
				@include media-breakpoint-up(xl) {
					margin-right:1rem;
				}
			}
		}
		.main-menu-link{
			color:$black;
			font-family:Bodoni;
			margin:0;
			&:hover{
				.name-dec {
					border-bottom: 2px solid #dd0934;
				}
			}
			
		}
		.nav-item,.nav-link{
			svg,path{
			//	fill:transparent;
			}
			&:hover{
				.name-dec {
					border-bottom: 2px solid #dd0934;
				}
			}
			.cart,.wish{
				padding: 0 1rem;
				display: flex;
				position:relative;
				cursor:pointer;
				&:before{
					content:"";
					display:block;
					position:absolute;
					height:10px;
					width:10px;
					border-radius:5px;
					background-color:$red;
					bottom:11px;
					left: 50%;
					margin-left: -5px;		
					@include media-breakpoint-up(xl) {
						bottom:6px;
					}
				}
				&.wish:before,&[count-items="0"]:before{
					display:none;
				}	
				+.dropdown-menu{
					@include media-breakpoint-up(lg) {
						top: 76px;
					}
					right:-100%;
					left:auto;
					transition: all .8s;
					background:$gray-153;
					display:block;
					color:#fff;
					padding:24px 28px;
					font-family:$input-btn-font-family;

					&.show{
						right:-65px;
					}
					span,a{
						color:#fff;
					}
					svg path{
						fill:#fff;
					}
					.h5{
						font-size:42px;
						text-transform:capitalize;
						font-family:Bodoni;
						font-weight:400;
						margin-bottom:42px;
					}
					.close{						
						opacity: 1;
						position: absolute;
						right: 80px;
						top: 35px;
						cursor:pointer;
					}
					.subtotal{
						margin: 34px 0 22px;
					}
					.price{
						word-spacing: -8px;
					}
					.list-product{
						.remove{
							display:flex;
							align-items: center;
							a{
								//position:relative;
								//left:-14px;
							}
						}
						
						.image{
							display:flex;
							background:#fff;	
							padding:4px;
							align-items: center;
							img{
								margin:auto;
							}
						}
						.list-details{
							.name{
								line-height: 18px;
								display:block;
							}
							.opt-Taglia,.opt-Size{
								display:none;
							}
							.option{
								margin-bottom:14px;
							}
							.qty{
								margin-bottom:14px;
								text-transform: lowercase;
							}
						}
					}
				}
			}
		}
	}
	#navbarNavRight{
	
		justify-content: flex-end;
		>div{
			width:auto;
			}
		.main-menu-link{
			@include media-breakpoint-down(lg){
				padding:.25rem .75rem;
			}
			margin-right:0;
			&.h4{
				margin-right:0;	
				@include media-breakpoint-up(xl){
					margin-right:1rem;	
				}
			}
		}
	}
	.right-menu-mobile{
		/*width:45%;
		text-align: right;*/
	}
	.navbar-brand{
		/*width:10%;
		text-align:center;
		margin:0;*/
		width: 33%;
    	text-align: center;
    	margin: 0 auto;
	}
	>.container-fluid{
		padding:13px;
		@include media-breakpoint-up(lg) {
			padding:20px;
		}
	}
	
	@include media-breakpoint-up(lg) {
		.navbar-expand-lg .navbar-nav .dropdown-menu{
			z-index:99;
		}
	}


	.dropdown-item.active, .dropdown-item:active{
		background-color:transparent;
		color:inherit;
		text-decoration:underline;
	}
	.dropdown-item:focus, .dropdown-item:hover{
		color: $primary;
    background-color: transparent;
		a{
			text-decoration:none;
		}
	}

	.dropdown-toggle::after{display:none;}
	
	@include media-breakpoint-down(md) {
		.nav-link.father{
			position:relative;
			&::after {
				display: block;
				position: absolute;
				right: 50px;
				content: '';
				width: 12px;
				height: 12px;
				border:0;
				border-bottom: solid 1px #000;
				border-right: solid 1px #000;
				transform: rotate(-45deg);
				top: 20px;
			}
			+.dropdown-menu-full.show{
				position:fixed;
				top:0;
				left:0;
				right:0;
				bottom:0;
				margin:0;
				overflow:auto;
				.back{
					top: 6px;
					position: absolute;
					right: auto;
					left: 20px;
					width: auto;
					padding: 0;
					margin: 0;
					svg{
						width: 20px;
						height: 20px;
					}
				}
				.h4:before{
				
				}
				p{
					white-space: break-spaces;
					margin-top:30px;
				}
			}
		}
	}

	.nav-btn{
		text-align: left;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 15px;
	}

	.navbar-toggler {
		padding:.35rem 0;
		width:33%;
		@include media-breakpoint-down(md) {
				height: 32px;
    		text-align: left;
				z-index: 11;
		}
		p{
			margin-top:2px;
		}
	}

	/*.navbar-toggler-icon {
		//background-image: $navbar-home-toggler-icon-bg;
		display: inline-flex;
		flex-direction: row;
		width: 6.25rem;
		margin: auto;
	}*/

	.navitem-toggler{
		@extend .navbar-toggler;
		padding:.35rem .75rem;
		text-align: left;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 15px;
	}
	.navitem-toggler-icon{
		@extend .navbar-toggler-icon;
		p{ margin-top:1px; }
	}

	&.header-home{
		.navbar-brand{
			>img.white{display:block;}
			>img.black{display:none;}
		}
		svg{
			fill:$black;
		}
		#dropdownCart .badge{
			border: 1px solid $white;
		}
	}
	&.header-general.menuOpen, &.header-home.menuOpen{
		background-color:$white;
	}
	&.header-general, &.header-home.menuOpen{
		.navbar-brand{
			>img.white{display:none;}
			>img.black{display:block;}
		}

		svg{
			fill:$black;
		}
		#dropdownCart .badge{
			border: 1px solid $gray-900;
			background-color: $gray-900;
			color: $white;
		}
	}

	@include media-breakpoint-down(md) {
		&.menuOpen{
			.navbar-brand{display:none;}
			.navbar-tool{position:absolute; top:0.5rem; right:0;}
		}
		&.cartOpen{
			background-color:$white;
			.nav-item .nav-link, .nav-item .nav-link svg{
				color: $gray-900 !important;
    		fill: $gray-900 !important;
			}
			svg{
				fill:$gray-900;
			}
			#dropdownCart .badge{
				border: 1px solid $gray-900;
				background-color: $gray-900;
				color: $white;
			}
		}
		&.menuOpen.searchOpen, &.cartOpen{
			.navbar-brand{
				display:block;
				>img.black {
    			display: block;
				}
				>img.white {
    			display: none;
				}
			}
		}
		
		.menu-icon, &.menuOpen.searchOpen .menu-icon, &.cartOpen .menu-icon{
			display:block;
			opacity:1;
		}
		.close-icon, &.menuOpen.searchOpen .close-icon, &.cartOpen .close-icon{
			display: none;
    	transition: opacity 1s ease-out;
    	opacity: 0;
		}
		&.menuOpen .menu-icon{
			display: none;
    	transition: opacity 1s ease-out;
    	opacity: 0;
		}
		&.menuOpen .close-icon{
			display:block;
			opacity:1;
		}
	}
	.sidenav{
		position: fixed;
		height: 100vh;
		background-color: #fff;
		top: 85px;
		left: -100%;
		width: 100%;
		z-index: 99;
		border-top: 1px solid rgba(0,0,0,.15);
		.navbar-nav{
			padding-left:1rem;
			padding-right:1rem;
			.nav-item{
				.nav-link{
					padding: 12px 1rem;
					position:relative;
					//font-size:14px;
					.fas{
						position: absolute;
						top: 18px;
						right: 25px;
					}
				}
			}
		}
		.dropdown-menu{
			border:0;
		}
		&.sidenavIIlvl{
			padding-top:16px;
			top:0;
			border-top:none;
			overflow-y:scroll;
			overflow-x:auto;
			font-family:Bodoni;
			.lang-change{
			position:absolute;
			bottom:0;
			left:0;
			right:0;
			}
			//.navbar-nav{
			//	border-top: 1px solid rgba(0,0,0,.15);
			//}
			.h3,.h4{
				margin-bottom:0;
				font-size:26px;
			}
			.navbar-toggler.is-open{
				top:21px;
				position:absolute;
				right:0;
				left:auto;
				width:auto!important;
				.navbar-toggler-icon{
					width:auto;
				}
			}
		}
		.dropdown-menu-full.show,.sidenav.sidenavIIlvl{
			padding-top:12px;
			.nav-link {
				padding: 12px 1.5rem;
				position: relative;
				font-size: 22px;
				text-transform:uppercase;
				
			}
			span.nav-link{
					font-size: 26px;
				}
			.navitem-toggler.is-open{
				top: 34px;
				position: absolute;
				right: auto;
				left: 32px;
				width: auto;
				padding: 0;
				margin: 0;
				svg{
					width:20px;
					height:20px;
				}
			}
		}
	}
@include media-breakpoint-down(md) {
	#navbarCart{
		.cart{
				position:relative;
				&:before{
					content:"";
					display:block;
					position:absolute;
					height:4px;
					width:4px;
					border-radius:5px;
					background-color:$red;
					bottom:9px;
					right: 13px;
					margin-left: -5px;		
					@include media-breakpoint-up(xl) {
						bottom:6px;
					}
				}
				&[count-items="0"]:before{
					display:none;
				}	
				+.dropdown-menu{
					position: fixed;
					top: 0;
					bottom: 0;
					right:-100%;
					left:100%;
					transition: all .8s;
					background:$gray-153;
					display:block;
					color:#fff;
					padding:24px 28px;
					border: 0;
    			margin: 0;
					font-family:$input-btn-font-family;
					&.show{
						right: 0;
						left: 0;
					}
					span,a{
						color:#fff;
					}
					svg path{
						fill:#fff;
					}
					.h5{
						font-size:42px;
						text-transform:capitalize;
						font-family:Bodoni;
						font-weight:400;
						margin-bottom:42px;
					}
					.close{						
						opacity: 1;
						position: absolute;
						right: 20px;
						top: 35px;
						cursor:pointer;
					}
					.subtotal{
						margin: 32px 0 22px;
					}
					.price{
						word-spacing: -8px;
					}
					.list-product{
						.remove{
							display:flex;
							align-items: center;
							a{
								//position:relative;
								//left:-14px;
							}
						}
						
						.image{
							display:flex;
							background:#fff;	
							padding:4px;
							align-items: center;
							img{
								margin:auto;
							}
						}
						.list-details{
							.name{
								line-height: 18px;
								display:block;
							}
							.opt-Taglia,.opt-Size{
								display:none;
							}
							.option{
								margin-bottom:14px;
							}
							.qty{
								margin-bottom:14px;
								text-transform: lowercase;
							}
						}
					}
				}
			}
		}
	}
}
#notification_carousel{
	height:45px;
	line-height:18px;
	background: #F7F7F7;
	.carousel-control-prev, .carousel-control-next{
		bottom:-3px;
	}
	@include media-breakpoint-down(md){
		.carousel-control-next{
			right:-8px;
		}
	}
	@include media-breakpoint-down(md){
		.carousel-control-prev{
			left:-8px;
		}
	}
	span{
		font-size:13px;
		letter-spacing: 0.45em;
		color:$black;
		font-family:  suiss;
			@include media-breakpoint-down(lg) {

		letter-spacing: normal;
	}		
	}
	@include media-breakpoint-down(xs) {
		span{
			font-size:8px;
		}

	}
	@include media-breakpoint-up(md) {
	
			line-height: 45px;
		}	
	
}
.lang-dropdown.show{
	top:76px;
	right:-20px;
	left: auto;
	transition: all .8s;
	background: #272727;
	display: block;
	color: #fff;
	padding: 24px 28px;
	font-family: suiss;
	a{
		color:#fff;
		text-decoration:none;
		&:hover{
			color:#fff!important;
			text-decoration:underline;
		}
	}
}
.flag-icon {
	width: 22px;
	height: 15px;
	background-size: cover;
	//border: 2px solid #fff;
	//border-radius: 20px;
	background-position: 50%;
	background-repeat: no-repeat;
	position: relative;
	display: inline-block;
}
.flag-icon-Italiano {
    background-image: url(https://img02.aws.kooomo-cloud.com/themes/1473/Rede_Version_01/images/flags/it-iso-8859-1.png);
}
.flag-icon-English {
    background-image: url(https://img02.aws.kooomo-cloud.com/themes/1473/Rede_Version_11_2022_UX_UI_Redesign/images/flags/gb.svg);
}



.dropdown-item{
	.back{
		p{
			font-size: 18px;
		}
	}
}

@media screen and (max-width: 768px) {
	.mobile-overflow {
		overflow-y: hidden;
	}

	.mobile-cart-fix {
		position: sticky;
		bottom: 0;
		background: #272727;
		height: 173px;
	}
}

.right-menu-mobile {
	.nav-link{
		@include media-breakpoint-down(md) {
			padding: 0.3rem!important;
		}
	}
}


.link-mob {
	font-size: 20px!important;
}

.lang-indicator {
	font-size: 14px;
	color: black;
}

.text-black {
	color: black;
}

.active-class {
	border-bottom: 2px solid #dd0934;
}