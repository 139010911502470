#guidataglie{
	.modal-footer{
		border-top:0;
	}
	@include media-breakpoint-down(sm) {
		.modal-body{
			padding:12px;
		}
	}
	.modal-lg{
		max-width:1200px;
		#size-guide-table{
			width:100%;
			padding:0;
			@include media-breakpoint-up(sm) {
				width:100%;
				padding:inherit;
			}
		}
		#size-guide-image{
			width:20%;
			padding-top:0;
			.img-fluid{
				width: 54%;margin: 0 auto;display: block;
			}
		}
	}
}

#size-guide{
	@include media-breakpoint-up(sm) {
		padding: 0;
		margin: 0px;
	}
	.h1{
		font-size: 20px;
    font-weight: bold;
		text-transform:uppercase;
		@include media-breakpoint-up(sm) {
			//margin-left: 10px;
		}
	}
	.h2{
		background: #000;
		color: #fff;
		padding: 3px 5px;
		font-size: 12px;
		margin-right: 0;
		text-transform:uppercase;
	}
	table {
    margin-bottom: 25px;
    width: 100%;
		border-collapse: separate;
    box-sizing: border-box;
	}
	td{
		padding: 3px 5px;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    font-size: 11px;
	}
	tr td:first-child, tr td.first-child {
    //text-align: left;
	}
	.header {
    font-weight: bold;
    padding-top: 5px;
    background: #eaeaea;
	}
	.odd {
    background: none;
	}
}