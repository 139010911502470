canvas.threesixty-zoom-canvas{display:none !important;} 

body.page_product{
	overflow-x: hidden;
	#site_main{
		margin-bottom:40px;
	}
	.next_prod{
		margin: 250px auto 0;
	}
	.suggestedProducts{
		h3{
			margin: 3rem 0;
			font-family:Bodoni;
		}
		img{
			width:100%;
		}
		.name{
			font-size:1rem;
			text-align:left;
			height:38px;
			margin-top:20px;
			@include media-breakpoint-down(md) {
				font-size:13px;
				height:36px;
			}
		}
		.price{
			position:absolute;
			right:21px;
			bottom:53px;
		}
		.prod{
			text-align: center;
		}
	}
	.grey-bg{
		background:#F6F6F6;
		padding-top:30px;
		@include media-breakpoint-down(md) {
			padding-top:30px;
		}
	}
	.flex-gallery{
    flex-direction: column;
		margin: 0 auto 0;
		//padding: 30px 0;
		#zoomMain{
			margin-bottom:7%;
			img{
				width:100%;
			}
		}
		[data-zg-role="gallery"]{
			>div{
				margin:0px;
			}
		}
	}
	.product-information {
		@include media-breakpoint-down(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
		h1 {
			font-size: 2.75rem;
		}
	}
	.product-details{
		h1{
			font-family:Bodoni;
			margin-bottom:30px;
			@include media-breakpoint-down(md) {
				font-size:30px;
			}
		}
		h3.price{
			font-size:1.5rem;
			margin-bottom:30px;
			@include media-breakpoint-down(md) {
				font-size:20px;
			}
		}
		.btn-add-to-cart{
			font-size:1.5rem;
			padding-left:28px;
			padding-right:28px;
			@include media-breakpoint-down(md) {
				font-size:1rem;
			}
		}
		.wish-col{
			margin-top:60px;
			text-transform:uppercase;
			.wish-icon{
				margin-left:12px;
				margin-top: -3px;
			}
		}
		.thumbnails.gallery-product{
			>div{
				display:flex;
			}
		}
	}
	.container-description{
		margin-top:36px;
		border:0;
		.h4{
			text-transform:uppercase;
			font-size: 1.125rem;
			padding:20px 0 10px;
			display:inline-block;
			border-bottom:solid 1.5px transparent;
			@include media-breakpoint-down(md) {
				font-size: .725rem;
			}
			&.active{
				text-decoration:none;
				border-bottom:solid 1.5px #000;
			}
			&:hover{
				text-decoration:none;
				border-bottom:solid 1.5px #000;
			}
		}	
 	}
	.tab-description{
		margin-top:25px;
		@include media-breakpoint-down(md) {
			margin-top:20px;
		}
	}




	ol.breadcrumb{
		border-bottom:none;
	}
	@include media-breakpoint-down(md) {
		#breadcrumb-container{
			display:none;
		}
	}
}

@include media-breakpoint-down(md) {
	.product-gallery{
		padding:0;
	}
}

.main-image-wrapper{
	@include media-breakpoint-up(lg) {
		margin-top: 0px;
    margin-left: 55px;
	}
}

.slider-wrapper{
	.swipe-container{
		&.swiper-container-vertical{
			position: absolute;
			top: 250px;
			width: inherit;
			max-width: calc(1680px * (1/12));
			&.scroll-stopped{
				bottom:0;
				top:inherit;
			}
			&.is-scrolled{
				position: fixed;
				width: inherit;
				top:250px;
				@include media-breakpoint-up(md) {
					max-width: calc(1680px * (1/12));
				}
			}
			.thumb-slider > a.thumbnail{
				display: block;
    		width: 44px;
    		margin-bottom: 1rem;
				opacity: 1;
				&.active{
					opacity:0.6;
				}
			}
		}
	}
}

#productCarousel{
		@include media-breakpoint-up(md) {
			.carousel-item{
				display:block!important;
				float:none!important;
			}
		}
		.carousel-control-next, .carousel-control-prev{
			top:0;
		}
		.carousel-control-next-icon, .carousel-control-prev-icon{
			width: 5px;
   		height: 9px;
		}
		.carousel-control-prev{
			left:-37px;
			
		}
		.carousel-control-prev-icon{
			background-image:url("#{$img-path}/product/product-arrow-left.png");
		}
		.carousel-control-next{
			right:0px;
		}
		.carousel-control-next-icon{
			background-image:url("#{$img-path}/product/product-arrow-rightt.png");
		}
		.mobile-back{
			position: absolute;
			left: 15px;
			top: 10px;
			height: 30px;
			z-index: 9;
			img{
				margin-right:3px;
			}
		}
		.mobile-arrow{
			position:absolute;
			right:0;
			top:13px;
			height:30px;
			z-index:9;
			.carousel-control-next{
				height: 20px;
				width: 37px;
				display:inline-block;
				text-align:center;
				.arrow-right{			
				}
			}
			.carousel-control-prev{
				height: 20px;
				width: 37px;
				display:inline-block;
				text-align:center;
				position:relative;
				.arrow-left{
				}
				&:after{
					position:absolute;
					display:inline-block;
					content:"";
					right:0;
					top:0;
					height:20px;
					width:1px;
					opacity: 0.2;
					background-color: #000000;
				}
			}
		}
	}


.infoCnt{
	hr{
		border-top:1px solid #E6E6E6;
	}
	>nav{
		border-bottom:1px solid #E6E6E6;
	}
	div.list-unstyled{
		> ul{
			padding-inline-start: 15px;
    	padding-inline-end: 15px;
		}
	}
	div.detail{
		padding:1rem;
	}
	li.item > a{
		position:relative;
		width:100%;
		float:left;
		padding:19px 0px;
		color:#000000;
		font-size: 13px;
		letter-spacing: 0.5px;
		line-height: 20px;
		svg{
			position:absolute;
			right:0;
			top:22px;
			&.plus{
				display:none;
			}
			&.minus{
				display:block;
			}
		}
		&.collapsed{
			svg{
				&.plus{
				display:block;
				}
				&.minus{
					display:none;
				}
			}
		}
	}
}


.to-discount{
	color:$orange;
}

.selected-combination{
	font-size: 13px;
  text-align: right;
	.option-0{
		display:none;
	}
}

.product-sizes{
	a{
		font-size: 13px;
		padding:4px 10px 4px 10px;
		font-weight:normal;
    	margin-right: 3px;
		border: 1px solid #d2d2d2;
		margin-bottom: 10px;
		&.active{
			border:1px solid #1a1a1a;
			background-color: #000;
			color: #fff;
		}
	}
}

#zoomMain{
	cursor: pointer;
	//cursor: url('#{$img-path}/custom/zoom-in-18.svg'), auto;
	//cursor: image-set(url('#{$img-path}/custom/zoom-in-18.svg') 1x, url('#{$img-path}/custom/zoom-in-36.svg') 2x),auto;
	//cursor: -webkit-image-set(url('#{$img-path}/custom/zoom-in-18.svg') 1x, url('#{$img-path}/custom/zoom-in-36.svg') 2x),auto;
}
.zoom-wrapper{
	display:none;
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1000;
	background:#fff;
	.zoom-container{
		display:block;
		width:100%;
		height:100%;
		margin:0 auto;
		background-repeat:no-repeat;
		background-size:cover;
		.zoom-thumb-wrapper{
			position:absolute;
			top:45%;
			left:16px;
			width:46px;
			-webkit-transform: translateY(50%);
			transform: translateY(-50%);
			.zoom-thumb-container{
				.btn-thumb{
					.item-thumb{
						width:46px;
						margin-top:12px;
						cursor:pointer;
						opacity:0.3;
					}
					&.selected{
						.item-thumb{
							border:2px solid #fff;
							opacity:1;
						}
					}
				}
			}
		}
		.zoom-close{
			position:absolute;
			top:16px;
			right:16px;
			display:block;
			width:24px;
			height:24px;
			cursor:pointer;
		}
	}
}


#q-shipping, #q-info, #q-descr{
	@include media-breakpoint-down(lg){
		min-height: 250px;
		max-height: 250px;
		overflow-y: auto;
	}
}

#guidataglie {
	.modal-content {
		background-color: white;
	}
}

#quickbuy_content {
	.btn-add-to-cart {
		font-size: 1.2rem;
		@include media-breakpoint-up(sm){
			padding-left: 28px;
			padding-right: 28px;
		}
		@include media-breakpoint-up(lg){
			font-size: 1.5rem;
		}
		@include media-breakpoint-down(sm){
			font-size: 1rem;
		}
	}
}