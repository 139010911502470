.card-confirm, .payment_methods {
	.card-header {
	 border:none!important;
	 background-color:#fff;
	}
}

.payment_methods {
	 border:none!important;
	}


.card-address { 
	.card-body, .card-header {
	 padding: 1.25rem;
	 height: auto;
	 //background-color: rgba(0,0,0,.03);
	 border: none!important;
	}
	
	.card-header {
	font-size: 11px;
	}

	.card-body {
	font-size: 14px;
	}
	.card-footer {
	background-color: transparent;
	border: none!important;
	}
}

.card-order { 
	 border: none!important;
	.card-body, .card-header {
	 padding: 1.25rem;
	 border: none!important;
	}
	
	.card-header {
	font-size: 11px;
	}

	.card-body {
	font-size: 14px;
	 background-color: rgba(0,0,0,.03);
	}
}

.card-shipping, .payment_methods{
	border: none!important;
	.card-header {
	 background-color: #fff;
	 border: none!important;
	 .card-title {
	 font-size: 1.2rem;
	 }
	}
	.custom-control-label {
	font-size: 1rem;
	
	}
}

.breadcrumb-payment {
	text-align: center;
	color: #D9D9D9;
	margin-bottom: 0;
	list-style: none;
	background-color: #fff;
	.breadcrumb-payment-step {
		border-bottom: 1px solid #D9D9D9;
		&.active {
		color: #000;
		border-bottom: 2px solid #000;
		}
	}
}

.summary-order {
	.card-header {
	font-size:11px;
	}
}

.login-checkout-tab {
	li {
	 &.active {
			border-bottom: solid 1px #1a1a1a;
		}
	a:hover {
	 text-decoration:none;
	}
	}
}

.product-order-tab {
	font-size: 0.75rem;
}