@font-face {
	font-family: suiss;
	font-weight:400;
	src: url("#{$fa-font-path}/Suiss.ttf") format("truetype")
}



@font-face {
	font-family: Bodoni;
	font-weight:400;
	src: url("#{$fa-font-path}/bodoni-seventytwo-itc-book.ttf") format("truetype")
}


